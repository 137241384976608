import React from "react";
import Link from "next/link";
import { items_offer_data } from "../../data/items_tabs_data";
import moment from "moment";

const LastInvestTab = ({ data }) => {
  return (
    <>
      {/* <!-- Offers --> */}
      <div
        className="tab-pane fade show active"
        id="offers"
        role="tabpanel"
        aria-labelledby="offers-tab"
      >
        <div
          role="table"
          className="scrollbar-custom dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 grid max-h-72 w-full grid-cols-3 overflow-y-auto rounded-lg rounded-tl-none border bg-white text-sm dark:text-white"
        >
          <div className="contents" role="row">
            <div
              className="dark:bg-jacarta-600 bg-light-base sticky top-0 py-2 px-4"
              role="columnheader"
            >
              <span className="text-jacarta-700 dark:text-jacarta-100 w-full overflow-hidden text-ellipsis">
                Amount
              </span>
            </div>
            <div
              className="dark:bg-jacarta-600 bg-light-base sticky top-0 py-2 px-4"
              role="columnheader"
            >
              <span className="text-jacarta-700 dark:text-jacarta-100 w-full overflow-hidden text-ellipsis">
                Address
              </span>
            </div>
            <div
              className="dark:bg-jacarta-600 bg-light-base sticky top-0 py-2 px-4"
              role="columnheader"
            >
              <span className="text-jacarta-700 dark:text-jacarta-100 w-full overflow-hidden text-ellipsis">
                Date
              </span>
            </div>
          </div>
          {data
            .filter((d) => d.status === 20)
            .map((item) => {
              const {
                id,
                amount: nxtu,
                wallet_address: name,
                created_at: date,
                vote_for,
              } = item;
              return (
                <div className="contents" role="row" key={id}>
                  <div
                    className="dark:border-jacarta-600 border-jacarta-100 flex items-center whitespace-nowrap border-t py-4 px-4"
                    role="cell"
                  >
                    <span className="-ml-1" data-tippy-content="ETH">
                      <svg className="icon mr-1 h-4 w-4">
                        <use xlinkHref="/icons.svg#icon-ETH"></use>
                      </svg>
                    </span>
                    <span
                      className={`text-sm font-medium tracking-tight ${
                        vote_for === undefined || vote_for === true
                          ? "text-green"
                          : "text-red"
                      }`}
                    >
                      {parseInt(nxtu).toFixed(2)} NXTU
                    </span>
                  </div>
                  <div
                    className="dark:border-jacarta-600 border-jacarta-100 flex items-center border-t py-4 px-4"
                    role="cell"
                  >
                    {name}
                  </div>
                  <div
                    className="dark:border-jacarta-600 border-jacarta-100 flex items-center border-t py-4 px-4"
                    role="cell"
                  >
                    {moment(date).format("DD MMM YYYY HH:mm")}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default LastInvestTab;
