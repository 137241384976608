import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import Link from "next/link";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import { useRouter } from "next/router";

const BidsCarousel = ({ items }) => {
  const router = useRouter();
  const pid = router.query.item;

  return (
    <>
      <Swiper
        modules={[Navigation, Pagination, Scrollbar]}
        spaceBetween={30}
        slidesPerView="auto"
        breakpoints={{
          240: {
            slidesPerView: 1,
          },
          565: {
            slidesPerView: 2,
          },
          1000: {
            slidesPerView: 3,
          },
          1100: {
            slidesPerView: 4,
          },
        }}
        navigation={{
          nextEl: ".bids-swiper-button-next",
          prevEl: ".bids-swiper-button-prev",
        }}
        className=" card-slider-4-columns !py-5"
      >
        {items
          .filter((i) => i.id != pid)
          .map((item, index) => {
            return (
              <SwiperSlide
                className="text-white"
                key={"item-" + item.id}
                virtualIndex={index}
              >
                <article>
                  <div className="dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 rounded-2xl block border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg text-jacarta-500">
                    <figure>
                      {/* {`item/${itemLink}`} */}
                      <Link href={"/item/" + item.id}>
                        <a>
                          <div className="w-full">
                            <img
                              src={item.url}
                              alt={item.name}
                              height={230}
                              width={230}
                              className="rounded-[0.625rem] w-full"
                              loading="lazy"
                            />
                          </div>
                        </a>
                      </Link>
                    </figure>
                    <div className="mt-4 flex items-center justify-between">
                      <Link href={"/item/" + item.id}>
                        <a>
                          <span className="font-display text-jacarta-700 hover:text-accent text-base dark:text-white">
                            {item.name}
                          </span>
                        </a>
                      </Link>
                    </div>
                  </div>
                </article>
              </SwiperSlide>
            );
          })}
      </Swiper>
      {/* <!-- Slider Navigation --> */}
      <div className="group bids-swiper-button-prev swiper-button-prev shadow-white-volume absolute !top-1/2 !-left-4 z-10 -mt-6 flex !h-12 !w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl sm:!-left-6 after:hidden">
        <MdKeyboardArrowLeft />
      </div>
      <div className="group bids-swiper-button-next swiper-button-next shadow-white-volume absolute !top-1/2 !-right-4 z-10 -mt-6 flex !h-12 !w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl sm:!-right-6 after:hidden">
        <MdKeyboardArrowRight />
      </div>
    </>
  );
};

export default BidsCarousel;
