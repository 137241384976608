import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import LastInvestTab from "./LastInvestTab";
import Brief from "./Brief";
import Activity_tab from "./Activity_tab";
import Price_history from "./Price_history";

const ItemsTabs = ({ data }) => {
  const [tabsActive, setTabsActive] = useState(1);
  const tabsHeadText = [
    {
      id: 1,
      text: "Last Transactions",
      icon: "offers",
    },
    {
      id: 2,
      text: "Details",
      icon: "details",
    },
  ];
  return (
    <>
      <div className="scrollbar-custom mt-14 overflow-x-auto rounded-lg">
        {/* <!-- Tabs Nav --> */}
        <Tabs className="min-w-fit tabs">
          <TabList className="nav nav-tabs flex items-center">
            {/* <!-- Offers --> */}
            {tabsHeadText.map(({ id, text, icon }) => {
              return (
                <Tab className="nav-item bg-transparent" key={id}>
                  <button
                    className={
                      tabsActive === id
                        ? "nav-link hover:text-jacarta-700 text-jacarta-400 relative flex items-center whitespace-nowrap py-3 px-6 dark:hover:text-white active"
                        : "nav-link hover:text-jacarta-700 text-jacarta-400 relative flex items-center whitespace-nowrap py-3 px-6 dark:hover:text-white"
                    }
                    onClick={() => setTabsActive(id)}
                  >
                    <svg className="icon mr-1 h-5 w-5 fill-current">
                      <use xlinkHref={`/icons.svg#icon-${icon}`}></use>
                    </svg>
                    <span className="font-display text-base font-medium">
                      {text}
                    </span>
                  </button>
                </Tab>
              );
            })}
          </TabList>

          <TabPanel className="tab-content">
            <LastInvestTab
              data={data.stakes.length > 0 ? data.stakes : data.votes}
            />
          </TabPanel>
          <TabPanel>
            {/* <!-- Details --> */}
            <div
              className="tab-pane fade"
              id="details"
              role="tabpanel"
              aria-labelledby="details-tab"
            >
              <div className="dark:bg-jacarta-700 space-y-2 dark:border-jacarta-600 border-jacarta-100 rounded-t-2lg rounded-b-2lg rounded-tl-none border bg-white p-6 md:p-10">
                {["name", "amount_to_collect", "definition", "detail"].map(
                  (key) => (
                    <div key={`item-${key}`} className="flex items-center">
                      <span className="dark:text-jacarta-300 mr-2 min-w-[9rem]">
                        {key.replaceAll("_", " ")}
                      </span>
                      <span className="text-white">{String(data[key])}</span>
                    </div>
                  )
                )}
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <Activity_tab />
          </TabPanel>
          <TabPanel>
            <Price_history classes="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 rounded-t-2lg rounded-b-2lg rounded-tl-none border bg-white p-6" />
          </TabPanel>
        </Tabs>
      </div>
    </>
  );
};

export default ItemsTabs;
