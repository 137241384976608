import moment from "moment";
import ProSaleCounter from "./ProSaleCounter";
import Link from "next/link";

const ProSaleBanner = () => {

  return (
    <div className="container -translate-y-24">
      <div className="rounded-2.5xl bg-light-base px-6 py-16 shadow-sm dark:bg-jacarta-700 md:px-16 lg:px-24">
        <div className="flex-wrap justify-between lg:flex">
          <div className="mb-14">
            <h2 className="mb-4 font-display text-3xl text-jacarta-700 dark:text-white">
              NXTU  is <span className="text-accent">Live!</span>
            </h2>
            <p className="mb-8 text-lg text-jacarta-500 dark:text-jacarta-300">
           
            </p>
            <Link href="https://latoken.com/exchange/NXTU_USDT">
              <a className="inline-block rounded-full bg-accent py-2.5 px-8 text-center text-sm font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark">
              Buy Now!
             
              </a>
            </Link>
          </div>
          {/* End left text */}

          <div>
           
            {/* End pro sale countdown banner */}

            {/* start Progress */}

            {/* End progress */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProSaleBanner;
